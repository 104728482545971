<template>
  <v-container
    fluid
    fill-height
    pa-0
    class="invitation-screen"
  >
    <v-row class="ma-0">
      <v-col cols=12 xs=12 sm=1 md=2> </v-col>
      <v-col cols=12 xs=12 sm=10 md=8>

        <SweepDetailsCard
          v-if="accepted === null"
          :sweep="sweep"
          showTeamVertical
          class="ml-2"
        >
          <template v-slot:actions>
            <v-col class="d-flex justify-end align-center pa-0">
              <div class="sweep-expirty-time py-1 px-2">
                {{formattedExpiryTime}}
              </div>
            </v-col>
          </template>
          <template v-slot:tier-details v-if="creativePayTier">
            <div class="pt-1">
              <span class="sub-heading"> Pay Tier:  </span>
              {{tier}}
            </div>
            <div class="pt-1">
              <span class="sub-heading"> Estimated Total Rate:  </span>
              ${{invitation.estTotal}}
            </div>
            <div class="pt-1">
            <span class="sub-heading"> Content Producer:  </span>
            {{contentProducer}}
            </div>
          </template>
          <template v-slot:invitation-actions v-if="invitationExpiryTime > 0">
            <v-btn class='mr-3 btn-purple btn-rounded-pill btn-primary-small' small
            v-on:click ="availabilityCheck(user.id) === '2'? acceptPopup('1') :
            updateInvitation('1', false)">
              Accept
            </v-btn>
            <v-btn
            small
            outlined
            color="info"
            class="btn-rounded-pill btn-primary-small"
            @click="updateInvitation('0', true)">
              Decline
            </v-btn>
          </template>
        </SweepDetailsCard>
        <template v-else>
          <v-card
           class="pa-8 sweep-details-card text-center"
           v-if="accepted == true">
            🎉  You’ve accepted the invitation! <br/>
            Your Content Producer will reach out to you with more details soon.
          </v-card>
          <v-card class="pa-8 sweep-details-card text-center" v-else>
            You’ve declined the invitation. See you next time!
          </v-card>
        </template>
      </v-col>
      <v-col cols=12 xs=12 sm=1 md=2> </v-col>
    </v-row>
    <template
        v-if="showInvitationPopup"
        class="pa-0"
      >
        <Modal
          :modal="showInvitationPopup"
          width="600"
          persistent
        >
          <InvitationDialog
            :creative="user"
            :invitationStatus="invitationStatus"
            @submit-invitation="submitInvitationForm"
            @close-invitation-dialog="showInvitationPopup=false"
            :askForReason="askReason"
          >
          </InvitationDialog>
        </Modal>
      </template>
      <template v-if="showConfirm">
      <v-dialog
        v-model="showConfirm"
        max-width="680"
      ><v-card class='overflow-hidden' flat>
        <div class="d-flex justify-end">
            <v-icon class="pa-4 pb-0 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
        </div>
        <v-card-text class="pb-1 black--text font-family-2 card-text">
        Looks like your status is set to Inactive.
        Tap on Confirm to set your status to Active and accept this invite.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text color="primaryGray1"
            @click="showConfirm = false"
            class="py-0"
          >
            Cancel
          </v-btn>
            <v-btn text color="info"
            @click="updateUserStatusApi()"
            class="py-0"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import SweepDetailsCard from '@/components/projects/sweep/SweepDetailsCard';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import Modal from '@/components/common/Modal';

export default {
  components: {
    InvitationDialog,
    Modal,
    SweepDetailsCard,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    invitationExpiryTime() {
      const invitation = _.find(this.sweep.sweepTeam, ['invitationToken', this.$route.params.invitationToken]);
      const expiration = moment(invitation.invitationSentAt).add(this.userDetails.inviteExpiry || 12, 'hours');
      return expiration.diff(moment(), 'minutes');
    },
    formattedExpiryTime() {
      if (this.invitationExpiryTime > 0) {
        return `${parseInt(this.invitationExpiryTime / 60, 0)} hr ${parseInt(this.invitationExpiryTime % 60, 0)} m remaining`;
      }
      return 'Invitation expired';
    },
    invitation() {
      return _.find(this.sweep.sweepTeam,
        (id) => id.invitationToken === this.$route.params.invitationToken);
    },
    contentProducer() {
      const producer = _.get(this.invitation, 'assignedUser');
      const name = `${producer.firstName} ${producer.lastName}`;
      return name;
    },
    creativePayTier() {
      return _.get(this.invitation, 'creativePayRate');
    },
    tier() {
      if (this.creativePayTier) {
        let tier = `${_.capitalize(this.creativePayTier.tier)} at $${this.creativePayTier.rate}`;
        if (this.creativePayTier.skillset.name !== 'Talent') {
          tier += '/hr';
        }
        return tier;
      }
      return '';
    },
  },
  data() {
    return {
      sweep: { },
      user: { },
      showInvitationPopup: false,
      invitationStatus: '',
      selectedReasonId: '',
      accepted: null,
      askReason: true,
      showConfirm: false,
    };
  },
  methods: {
    ...mapActions('sweep', ['getInvitationByToken', 'updateInvitationByToken']),
    ...mapActions('user', ['creativeStatusUpdate']),
    updateInvitation(status, needReason) {
      this.invitationStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    availabilityCheck(userId) {
      let check = '';
      _.map(this.sweep.sweepTeam, (creative) => {
        if (creative.creatives.id === userId) {
          check = creative.creatives.availability;
        }
      });
      return check;
    },
    acceptPopup(status) {
      this.invitationStatus = status;
      this.showConfirm = true;
    },
    async updateUserStatusApi() {
      const response = await this.updateInvitationByToken({
        status: this.invitationStatus,
        invitationToken: this.$route.params.invitationToken,
      });
      this.accepted = this.invitationStatus;
      if (response.success) {
        this.showConfirm = false;
      }
    },
    async submitInvitationForm(reasonId) {
      const result = await this.updateInvitationByToken({
        reasonId,
        status: this.invitationStatus,
        invitationToken: this.$route.params.invitationToken,
      });
      if (result.success) {
        this.showInvitationPopup = false;
        this.accepted = this.invitationStatus;
      }
    },

    async getInvitation(inviteToken) {
      const result = await this.getInvitationByToken(inviteToken);
      if (result.success) {
        this.sweep = result.sweep;
        this.user = result.user;
        this.accepted = this.invitation.accepted;
      } else {
        this.$router.push({ path: '/dashboard' });
      }
    },
  },
  mounted() {
    this.getInvitation(this.$route.params.invitationToken);
  },
};
</script>

<style scoped lang="scss">
  .invitation-screen {
    color: black;
    font-family: $fontFamily1;
  }
  .font-family-2 {
    font-family: $fontFamily1;
  }
  .heading, .sub-heading {
    font-weight: bold;
    padding: 5px 0;
  }
  .heading {
    font-size: 18px;
  }
  .sub-heading {
    font-size: 16px;
  }
</style>
